import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { PASSWORD_PATTERN } from '@util/common';
import { LoaderService, AuthService, LanguageService } from '@services/public';

const PATH = `pages.public.reset`;

@Component({
  selector: 'app-public-password-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class PublicPasswordResetComponent implements OnInit {

  public form: UntypedFormGroup
  public code: string;
  hidePassword: boolean = true;
  hideConfirmPassword: boolean = true;
  pageLanguage: any;

  constructor(
    private authSvc: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private loaderSvc: LoaderService,
    private _languageSvc: LanguageService) { }

  ngOnInit(): void {
    // Load page Language
    this._languageSvc.get([PATH]).then((valuse)=>this.pageLanguage=valuse[PATH]);
    this.code = this.route.snapshot.paramMap.get('code');;
    this.form = new UntypedFormGroup({
      password: new UntypedFormControl(null, [Validators.required, Validators.pattern(PASSWORD_PATTERN)]),
      confirmPassword: new UntypedFormControl('', [Validators.required]),
    }, this.matchPasswords);
  }

  get f() {
    return this.form.controls;
  }

  private matchPasswords(form: UntypedFormGroup): any {
    if (form.value.password === form.value.confirmPassword) return null;
    return { 'mismatch': true };
  }

  resetPassword() {
    // Validate form.
    this.form.markAllAsTouched();
    if (this.form.invalid) return;

    // Reset password.
    const loader: unique symbol = Symbol();
    this.loaderSvc.addLoader(loader);
    this.authSvc.resetPassword(this.code, this.form.value.password).pipe(map(
      (response: any) => {
        if (!!response.success) {
          this.form.reset();
          this.loaderSvc.removeLoader(loader);
          Swal.fire({
            title: this.pageLanguage?.passReset,
            icon: 'success',
            confirmButtonColor: '#456790',
            confirmButtonText: this.pageLanguage?.ok,
            didDestroy: () => this.router.navigate(['/login'])
          });
        }
      }).bind(this)).subscribe();
  }
}
