import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { JOURNAL_PAGE, JournalData } from '../common';
import { NO_HTML_PATTERN } from '@util';
import { LanguageService, StorageService, TEMP_KEYS } from '@services/public';

const PAGE_PATH: string = 'skillBuilders.journal.pages.userSummary';

@Component({
  selector: 'app-member-skill-builder-journal-user-summary',
  templateUrl: './user-summary.component.html',
  styleUrls: ['./user-summary.component.scss']
})
export class MemberSkillBuilderJournalUserSummaryComponent implements OnInit {

  @Input() data: JournalData;
  form: UntypedFormGroup;
  submitted: boolean = false;
  maxChars: number = 400;

  // Page language.
  page: { [key: string]: string } = {
    title: '',
    placeholder: '',
    next: '',
    error: ''
  };

  constructor(
    private _storageSvc: StorageService,
    private _languageSvc: LanguageService
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page)
          this._languageSvc.template(this.page[key]).then(
            value => this.page[key] = value);
      });

    // Setup form.
    this.form = new UntypedFormGroup({
      userSummary: new UntypedFormControl(this.data?.userSummary || '',
        [Validators.required, Validators.pattern(NO_HTML_PATTERN)])
    });
  }
  
  nextOnClick(): void {
    this.form.controls.userSummary.setValue(
      (this.form.controls.userSummary.value || '').trim());
    this.submitted = true;
    if (!this.form.valid) return;
    const data: JournalData = Object.assign({}, this.data, {
      page: JOURNAL_PAGE.GENERATED_SUMMARY,
      userSummary: this.form.controls.userSummary.value
    });
    this._storageSvc.setTempStorage(TEMP_KEYS.JOURNAL, data);
  }

}
