import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';

import { Option, Skill, LoaderService, DomainService, LanguageService, TEMP_KEYS, StorageService } from '@services/public';
import { AssessmentService, User } from '@services/member/assessment.service';
import { UserAgentScreenService } from '@services/member/user-agent-screen.service';

@Component({
  selector: 'app-member-assessment-leader',
  templateUrl: './leader.component.html',
  styleUrls: ['./leader.component.scss']
})
export class MemberAssessmentLeaderComponent implements OnInit {

  // Assessment
  uuid: string = null;
  options: Array<Option> = [];
  instructions: string = '';
  users: Array<User> = [];
  skills: Array<Skill> = [];
  scores: Array<any> = [];

  // Form control
  form: UntypedFormGroup = new UntypedFormGroup({});
  currentSkillIndex: number = 0;

  allSkills: Array<Skill> = [];

   // Language Translation
   pageLanguage: any;
   appLabels: any;
   basePath = `pages.member.assessment`;

  constructor(
    private toastSvc: ToastrService,
    private domainSvc: DomainService,
    private assessmentSvc: AssessmentService,
    private route: ActivatedRoute,
    private router: Router,
    private loaderSvc: LoaderService,
    private _languageSvc: LanguageService,
    private userSrc: UserAgentScreenService,
    private _storageSvc: StorageService) { }

  ngOnInit(): void {
    // Store uuid.
    this.router.events.subscribe(x => {
      if (x instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
    this.uuid = this.route.snapshot.paramMap.get('uuid');

    // Load instructions and options.
    const path: string = 'assessments.leader';
    this._languageSvc.get([path]).then(
      value => {
        if (
          typeof value[path] !== 'object' ||
          value[path] === null
        ) return;
        this.options = Object.values(value[path].options);
        this.instructions = value[path].instructions;
      });

      // Load Language
      this._languageSvc.get([this.basePath]).then((value) => {
        this.pageLanguage = value[this.basePath];
      });
      this._languageSvc.get([`labels`]).then((value)=> {
        this.appLabels = value[`labels`];
      });

    // Load skills.
    this.domainSvc.getSkills().then(
      response => {
        this.allSkills = response;
        this.loadAssessment();
      });
  }

  loadAssessment(): void {
    // Load assessment.
    const loader: unique symbol = Symbol();
    this.loaderSvc.addLoader(loader);
    this.assessmentSvc.getLeader(this.uuid).pipe(map(
      (response: any) => {
        if (!!response.users) {
          this.users = response.users;
          this.users.forEach(
            user => {
              this.form.addControl(user.uuid, new UntypedFormControl('', Validators.required));
            });
        }
        if (!!response.skillUIDs)
          this.skills = this.allSkills.filter(skill => response.skillUIDs.includes(skill.uid));
        this.loaderSvc.removeLoader(loader);
      }).bind(this)).subscribe();
  }

  prev(): void {
    // Store score.
    this.scores[this.currentSkillIndex] = {};
    for (const control in this.form.value)
      this.scores[this.currentSkillIndex][control] = this.form.value[control];

    // Update form.
    this.currentSkillIndex--;
    this.form.reset();
    if (typeof this.scores[this.currentSkillIndex] !== 'undefined')
      for (const control in this.form.value)
        this.form.controls[control].patchValue(this.scores[this.currentSkillIndex][control]);
  }

  next(): void {
    // Make sure question is answered.
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      this.toastSvc.error(this.pageLanguage.page.mustFullyAnswer)
      return;
    }
    window.scrollTo(0, 0);
    // Store score.
    this.scores[this.currentSkillIndex] = {};
    for (const control in this.form.value)
      this.scores[this.currentSkillIndex][control] = this.form.value[control];

    // Update form.
    this.currentSkillIndex++;
    this.form.reset();
    if (typeof this.scores[this.currentSkillIndex] !== 'undefined')
      for (const control in this.form.value)
        this.form.controls[control].patchValue(this.scores[this.currentSkillIndex][control]);
  }

  finish(): void {
    // Make sure question is answered.
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      this.toastSvc.error(this.pageLanguage.page.mustFullyAnswer)
      return;
    }

    // Store score.
    this.scores[this.currentSkillIndex] = {};
    for (const control in this.form.value)
      this.scores[this.currentSkillIndex][control] = this.form.value[control];

    // Compile scores.
    const skillScores = [];
    for (let i = 0; i < this.skills.length; i++) {
      const userScores = [];
      for (const uuid in this.scores[i])
        if (!!this.scores[i][uuid])
          userScores.push({ uuid, score: this.scores[i][uuid] });
      skillScores.push({
        uid: this.skills[i].uid,
        scores: userScores
      });
    }

    // Submit assessment.
    const loader: unique symbol = Symbol();
    this.loaderSvc.addLoader(loader);
    this.assessmentSvc.submitLeader(this.uuid, skillScores).pipe(map(
      (response: any) => {
        if (!!response.success) {
          this.loaderSvc.removeLoader(loader);
          this.toastSvc.success(this.pageLanguage.page.submitSuccess);
          this.router.navigate(["/member/dashboard/reports"]);
        }
      }).bind(this)).subscribe();
  }
}
