import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';

import { LoaderService, LanguageService } from '@services/public';
import { GroupService as LeaderGroup, OrganizationService } from '@services/member';
import { GroupService  } from '@services/leader';

const PAGE_PATH = `pages.leader.group.create`;
@Component({
  selector: 'app-leader-group-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class LeaderGroupCreateComponent implements OnInit {

  form: UntypedFormGroup;
  organizations: Array<any> = [];
  // Page language.
  page: {[key: string]: string} = {
  }
  constructor(
    private _languageSvc: LanguageService,
    private groupSvc: GroupService,
    private organizationSvc: OrganizationService,
    private modalSvc: BsModalService,
    private toastSvc: ToastrService,
    private loaderSvc: LoaderService,
    private leaderGroupSvc:LeaderGroup) { }

  ngOnInit(): void {
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
      this.page = value[PAGE_PATH];
      for (const key in this.page)
        this._languageSvc.template(this.page[key]).then(
          value => this.page[key] = value);
      }
    );  
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
      abbreviation: new UntypedFormControl(null, []),
      organization: new UntypedFormControl(null, [Validators.required]),
      startDate: new UntypedFormControl(null, [Validators.required]),
      endDate: new UntypedFormControl(null, [Validators.required])
    }, this.dateValidator);

    // Get organizations.
    const loader: unique symbol = Symbol();
    this.loaderSvc.addLoader(loader);
    this.organizationSvc.getOrganizations().pipe(map(
      (response: any) => {
        if (!!response.organizations) {
          this.organizations = response.organizations;
          this.loaderSvc.removeLoader(loader);
        }
      }).bind(this)).subscribe();
  }

  get f(): any {
    return this.form.controls;
  }

  private dateValidator(form: UntypedFormGroup): any {
    if (!form.value.startDate || !form.value.endDate || new Date(form.value.startDate) < new Date(form.value.endDate))
      return console.log(form.value.startDate, form.value.endDate);
    //return { 'dateConflict': true };
  }

  onSubmit() {
    // Validate form.
    this.form.markAllAsTouched();
    if (this.form.invalid) return;

    // Register group.
    const loader: unique symbol = Symbol();
    this.loaderSvc.addLoader(loader);
    this.groupSvc.createGroup(this.form.value).pipe(map(
      (response: any) => {
        if (!!response.success) {
          this.loaderSvc.removeLoader(loader);
          this.closeModal();
          this.toastSvc.success(this.page?.groupAdded);
          this.leaderGroupSvc.loadGroups();
        }
      }).bind(this)).subscribe();
  }

  closeModal() {
    this.modalSvc.hide();
  }
}
