import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { DECISION_PAGE, DecisionAction, DecisionData } from '../common';
import { LanguageService, StorageService, TEMP_KEYS } from '@services/public';

const PAGE_PATH: string = 'skillBuilders.decision.pages.impacts';

@Component({
  selector: 'app-member-skill-builder-decision-impacts',
  templateUrl: './impacts.component.html',
  styleUrls: ['./impacts.component.scss']
})
export class MemberSkillBuilderDecisionImpactsComponent implements OnInit {

  @Input() data: DecisionData;
  form: UntypedFormGroup;
  submitted: boolean = false;

  // Page langauge.
  page: {[key: string]: string | Array<string>} = {
    title: '',
    positiveOutcome: [],
    negativeOutcome: [],
    noImpact: '',
    positiveImpact: '',
    negativeImpact: '',
    next: '',
    error1: ''
  }

  constructor(
    private _storageSvc: StorageService,
    private _languageSvc: LanguageService
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page) {
          switch (key) {
            case 'positiveOutcome': {
              const positiveOutcome: string = <string>this.page[key];
              this.page[key] = [];
              const actions: Array<DecisionAction> =
                <Array<DecisionAction>>this.data?.actions;
              for (let i = 0; i < actions.length; i++) {
                this._languageSvc.template(
                  positiveOutcome, { action: actions[i].action }
                ).then(value => (<Array<string>>this.page[key])[i] = value);
              }
              break;
            }
            case 'negativeOutcome': {
              const negativeOutcome: string = <string>this.page[key];
              this.page[key] = [];
              const actions: Array<DecisionAction> =
                <Array<DecisionAction>>this.data?.actions;
              for (let i = 0; i < actions.length; i++) {
                this._languageSvc.template(
                  negativeOutcome, { action: actions[i].action }
                ).then(value => (<Array<string>>this.page[key])[i] = value);
              }
              break;
            }
            default:
              this._languageSvc.template(<string>this.page[key]).then(
                value => this.page[key] = value);
          }
        }
      });

    // Setup form
    this.form = new UntypedFormGroup({
      actions: new UntypedFormGroup({}), // ngx-star-rating does not work with FormArray
    });
    const actions: Array<DecisionAction> =
      <Array<DecisionAction>>this.data?.actions;
    for (let i = 0; i < actions.length; i++) {
      this.actions.addControl(i.toString(), new UntypedFormGroup({
        positiveImpact: new UntypedFormControl(
          (typeof actions[i].positiveImpact === 'number') ?
            actions[i].positiveImpact + 1 : null,
          [Validators.required]),
        negativeImpact: new UntypedFormControl(
          (typeof actions[i].negativeImpact === 'number') ?
            actions[i].negativeImpact + 1 : null,
          [Validators.required]),
      }));
    }
  }

  get actions(): UntypedFormGroup {
    return <UntypedFormGroup>this.form.controls['actions'];
  }

  get actionKeys(): Array<string> {
    return Object.keys(this.actions.controls);
  }

  getActionControls(i: string): { [key: string]: AbstractControl } {
    return (<UntypedFormGroup>this.actions.controls[i]).controls;
  }

  nextOnClick(): void {
    this.submitted = true;
    if (!this.form.valid) return;
    for (let i = 0; i < this.data.actions.length; i++) {
      const action: DecisionAction = <DecisionAction>this.data.actions[i];
      action.positiveImpact = this.actions.controls[i].value.positiveImpact - 1;
      action.negativeImpact = this.actions.controls[i].value.negativeImpact - 1;
      action.rank = action.positiveImpact - action.negativeImpact;
    }
    const data: DecisionData = Object.assign({}, this.data, {
      page: DECISION_PAGE.RANKS
    });
    this._storageSvc.setTempStorage(TEMP_KEYS.DECISION, data);
  }

}
