import { Component, Input, OnInit } from '@angular/core';
import { LanguageService, StorageService, TEMP_KEYS } from '@services/public';
import { REFLECTION_PAGE, ReflectionData } from '../common';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NO_HTML_PATTERN } from '@util';

const PAGE_PATH: string = 'skillBuilders.reflection.pages.customAction';

@Component({
  selector: 'app-member-skill-builder-reflection-custom-action',
  templateUrl: './custom-action.component.html',
  styleUrls: ['./custom-action.component.scss']
})
export class MemberSkillBuilderReflectionCustomActionComponent
  implements OnInit
{

  @Input() data: ReflectionData;
  form: UntypedFormGroup;
  submitted: boolean = false;
  maxChars: number = 400;

  // Page langauge.
  page: {[key: string]: string} = {
    title: '',
    subTitle: '',
    placeholder: '',
    error1: '',
    error2: '',
    next: ''
  }

  constructor(
    private _languageSvc: LanguageService,
    private _storageSvc: StorageService
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page) {
          switch (key) {
            case 'title': {
              this._languageSvc.template(
                this.page[key],
                { skill: this.data.skill }
              ).then(value => this.page[key] = value);
              break;
            }
            default: {
              this._languageSvc.template(this.page[key]).then(
                value => this.page[key] = value);
            }
          }
        }
      });

    // Setup form.
    this.form = new UntypedFormGroup({
      action: new UntypedFormControl(this.data?.action || '',
        [Validators.required, Validators.pattern(NO_HTML_PATTERN)])
    });
  }

  nextOnClick(): void {
    this.form.controls.action.setValue(
      (this.form.controls.action.value || '').trim());
    this.submitted = true;
    if (!this.form.valid) return;
    this._storageSvc.setTempStorage(TEMP_KEYS.REFLECTION, {
      ...this.data,
      page: REFLECTION_PAGE.SUMMARY,
      action: this.form.controls.action.value,
      skillBuilderType: null
    });
  }

}
