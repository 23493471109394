import { Component, OnInit,OnDestroy } from '@angular/core';
import { LeaderMetricItem, LeaderMetricsData, LeaderRewardsService } from '@services/leader/leader-rewards.service';
import { map } from "rxjs/operators";
import {
  Group,
  GroupService,
  NO_GROUPS,
  NO_ORGANIZATIONS,
  Organization,
  OrganizationService
} from '@services/member';
import {
  LeaderboardCard
} from "@services/member/rewards.service";
import {
  UserService,
} from '@services/viewer/user.service';
import {
    LoaderService,
    LanguageService
  } from "@services/public";
import { Subscription } from 'rxjs';
import { AVATARS, Filter, getAvatarCoordinates } from '@util';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { JDate } from '@util/jdate';

const PAGE_PATH = `pages.leader.dashboard.leaderReward`;

@Component({
  selector: 'app-leader-reward',
  templateUrl: './leader-reward.component.html',
  styleUrls: ['./leader-reward.component.scss']
})
export class LeaderRewardComponent implements OnInit, OnDestroy {
  // organizations
  organizations: Array<Organization> = []
  selectedOrg: string = null;
  //groups
  groups: Array<Group>
  selectedGroup: Group = null;
  // leaderboard
  leaderboard: Array<LeaderboardCard> | null = [];
  top3Leaderboard: Array<LeaderboardCard> | null = [];
  lower5Leaderboard: Array<LeaderboardCard> | null = [];
  userUUID: any = ""
  // metrics
  startDate: string = this.findMonday();
  currentPage: number = 1
  metricsData: LeaderMetricsData;
  metricsItems: Array<LeaderMetricItem> = [];
  prevMetricsQuery: any = {}
  pagination = this.setupPagination();
  private _subscriptions: Subscription = new Subscription();
  groupFilter: Filter<Group> = {
    key: "",
    value: null,
    options: []
  }
  form: UntypedFormGroup;
  startDateInput:HTMLElement;
  sortColumn: string = "score";
  sortOrder: string = "DESC";
  isOrientation: boolean = false;
  // Page language.
  page: {[key: string]: string} = {
  }

  constructor(
    private loaderSvc: LoaderService,
    private rewardsSvc: LeaderRewardsService,
    private groupSvc: GroupService,
    private userSvc: UserService,
    private orgSvc: OrganizationService,
    private _languageSvc: LanguageService
  ) { }

  ngOnInit(): void {
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page)
          this._languageSvc.template(this.page[key]).then(
            value => this.page[key] = value);
      }
    );
    this.getOrganizations();
    this.getGroups();
    this.form = new UntypedFormGroup({
      startDate: new UntypedFormControl(null, [Validators.required])
    }, this.dateValidator);
  }


  get f(): any {
    return this.form.controls;
  }

  private dateValidator(form: UntypedFormGroup): any {
    if (!form.value.startDate)
    return { 'dateConflict': true };

  }

  openDatepicker(startDateField) {
    startDateField.click();
  }

  getAvatarStyle(avatar: AVATARS, size?: number) {
    const { x, y } = getAvatarCoordinates(avatar, size);
    return {'background-position': `${-x}px ${-y}px`};
  }

  // organizations
  getOrganizations() {
    const orgUUID = localStorage.getItem("organizationUUID");
    this._subscriptions.add(this.orgSvc.organizations.subscribe((organization) => {
      this.organizations = organization;
      if (orgUUID !== null) {
        const org = this.organizations.find(
          (element) => element.uuid === orgUUID
        );
        this.selectedOrg = org.uuid;
        //this.isAddTag = org.createTag;
      }
      //this.getAlldata(organizationUUID);
      this.onSelectOrganization();

    }));
    if (orgUUID === null)
      this.organizations.push(NO_ORGANIZATIONS);
  }

  onSelectOrganization() {
    if (this.selectedOrg !== null) {
      const org = this.organizations.find((element) => element.uuid === this.selectedOrg);
      //this.isAddTag = org.addUser;
      localStorage.setItem("organizationUUID", this.selectedOrg);
    }
    this.getLeaderboardData(this.selectedOrg);
    if (!!this.groups) {
      this.groupFilter.options = this.groups.filter(group => group.organizationUUID == this.selectedOrg)
      if (this.groupFilter.options.length == 0) {
        this.groupFilter.options.push(NO_GROUPS);
      }
      this.groupFilter.value = this.groupFilter.options[0];
    }
  }

  // groups
  onSelectGroup() {
    if (this.groupFilter.value.uuid == null) return
    this.getMetrics(this.startDate, this.groupFilter.value.uuid, this.currentPage, this.sortColumn, this.sortOrder, this.isOrientation);
  }

  getGroups() {
    //load group
    this.groupSvc.groups.subscribe((groups) => {
      this.groups = groups;

      if (this.organizations.length > 0) {
        if (this.selectedOrg == null) {
          this.groupFilter.options = [NO_GROUPS];
          this.groupFilter.value = this.groupFilter.options[0];
          return
        }
        this.groupFilter.options = this.groups.filter(group => group.organizationUUID == this.selectedOrg && group.memberUUIDs.length > 0);
        if (this.groupFilter.options.length == 0) {
          this.groupFilter.options.push(NO_GROUPS);
        }
        this.groupFilter.value = this.groupFilter.options[0];
      }
      this.onSelectGroup();
    });
  }

  // metrics
  getMetrics(startDate: string, groupUUID: string, currPage?: number, sortColumn?: string, sortOrder?: string, isOrientation?: boolean) {
    const loader: unique symbol = Symbol();
    this.loaderSvc.addLoader(loader);
    if (groupUUID !== undefined) {
      this.prevMetricsQuery = {
        startDate: startDate,
        groupUUID: groupUUID,
        currPage: currPage
      }
    }

    this.rewardsSvc
      .loadMetricsData(startDate, groupUUID, currPage, sortOrder, sortColumn, isOrientation)
      .pipe(
        map((response: any) => {
          if (!response.errors) {
            this.metricsItems = [];
            this.metricsData = response;
            this.metricsData.records.forEach((metricItem) =>  this.getUser(metricItem));
            this.pagination = this.setupPagination();
            this.loaderSvc.removeLoader(loader);
          }
        }).bind(this)
      )
      .subscribe();
  }

  findMonday(): string {
    const jdate = new JDate();
    const diff = (jdate.weekday === 0) ? 6 : jdate.weekday - 1;
    const newDate = jdate.addDays(-diff);
    return newDate.toString();
  }

  convertDateFormat(dateStr: string): string {
    const parts = dateStr.split("/");
    return `${parts[2]}-${parts[0]}-${parts[1]}`;
}

  updateMetricsDate(startDateField) {
    this.currentPage = 1;
    this.startDate = this.convertDateFormat(startDateField.value)
    this.getMetrics(
      this.startDate,
      this.prevMetricsQuery.groupUUID,
      this.currentPage,
      this.sortColumn,
      this.sortOrder,
      this.isOrientation
    );
  }

  setupPagination() {
    let tempPagination = [];
    if (this.metricsData == undefined || this.metricsData == null) return [];
    if (this.metricsData.metadata.totalPages <= 4) {
      for (let x = 1; x <= this.metricsData.metadata.totalPages; x++) {
        tempPagination.push({
          pageNum: x, disabled: this.currentPage == x ? true : false, destination: {...this.prevMetricsQuery, currPage: x}})
      }
      return tempPagination
    }
    if (this.metricsData.metadata.totalPages > 4) {
      if (this.currentPage <= 3) {
        for (let x = 1; x <= this.metricsData.metadata.totalPages; x++) {
          tempPagination.push({
            pageNum: x, disabled: this.currentPage == x ? true : false, destination: {...this.prevMetricsQuery, currPage: x}})
        }
        return tempPagination
      }
      if (this.currentPage > this.metricsData.metadata.totalPages - 3) {
        let totalPages = this.metricsData.metadata.totalPages;
        for (let x = totalPages - 4; x <= this.metricsData.metadata.totalPages; x++) {
          tempPagination.push({
            pageNum: x, disabled: this.currentPage == x ? true : false, destination: {...this.prevMetricsQuery, currPage: x}})
        }
        return tempPagination;
      }
      if (this.currentPage >= 3 && this.currentPage <= this.metricsData.metadata.totalPages - 3) {
        let selectedPage = this.currentPage;
        return [
          {pageNum: selectedPage - 3, disabled: false, destination: {...this.prevMetricsQuery, currPage: selectedPage - 2}},
          {pageNum: selectedPage - 2, disabled: false, destination: {...this.prevMetricsQuery, currPage: selectedPage - 1}},
          {pageNum: selectedPage,
            disabled: true,
            destination: {...this.prevMetricsQuery, currPage: selectedPage}
          },
          {pageNum: selectedPage - 1, disabled: false, destination: {...this.prevMetricsQuery, currPage: selectedPage + 1}},
          {pageNum: selectedPage + 1, disabled: false, destination: {...this.prevMetricsQuery, currPage: selectedPage + 2}}
        ]
      }
    }
  }

  goToUserLeaderboard(userUUID) {
    return;
  }

  pageBtnClick(destination) {
    if (typeof destination == 'string') {
      if (destination == 'prev') this.currentPage-=1;
      if (destination == 'next') this.currentPage+=1;
      this.getMetrics(this.prevMetricsQuery.startDate, this.prevMetricsQuery.groupUUID, this.currentPage, this.sortColumn, this.sortOrder, this.isOrientation);
    } else {
      this.currentPage = destination.currPage;
      this.getMetrics(destination.startDate, destination.groupUUID, destination.currPage, this.sortColumn, this.sortOrder, this.isOrientation);
    }
  }

  // users
  async getUser(metricItem?:any, leaderCard?: LeaderboardCard) {
    let userUUID = metricItem?.userUUID || leaderCard?.userUUID;
    const user = await this.userSvc
    .getUserByUUID(userUUID)
      if (metricItem){
        this.metricsItems.push({...metricItem, name: `${user.name}`} );
      }
      if (leaderCard && user) {
        leaderCard.name = `${user.name}`;
      }
  }

  onSortChange(sortKey: string) {
    switch (sortKey) {
      case 'scoreHighToLow':
        this.sortColumn = 'score';
        this.sortOrder = 'DESC';
        break;
      case 'scoreLowToHigh':
        this.sortColumn = 'score';
        this.sortOrder = 'ASC';
        break;
      case 'extraCreditHighToLow':
        this.sortColumn = 'extraCredit';
        this.sortOrder = 'DESC';
        break;
      case 'extraCreditLowToHigh':
        this.sortColumn = 'extraCredit';
        this.sortOrder = 'ASC';
        break;
      case 'name':
        this.sortColumn = 'name';
        this.sortOrder = 'ASC';
        break;
      default:
        return;
    }

    this.getMetrics(this.prevMetricsQuery.startDate, this.prevMetricsQuery.groupUUID, this.currentPage, this.sortColumn, this.sortOrder, this.isOrientation);
  }

  onOrientationChange(isOrientationOn: boolean){
    this.isOrientation = isOrientationOn;
    this.getMetrics(this.prevMetricsQuery.startDate, this.prevMetricsQuery.groupUUID, this.currentPage, this.sortColumn, this.sortOrder, this.isOrientation);
  }

  // leaderboard
  getLeaderboardData(orgUUID?: string) {
    const loader: unique symbol = Symbol();
    this.loaderSvc.addLoader(loader);
    this.rewardsSvc
      .loadLeaderboardData(orgUUID, this.userUUID)
      .pipe(
        map((response: any) => {
          if (!response.errors) {
            this.leaderboard = response;
            this.loaderSvc.removeLoader(loader);
            this.buildLeaderboard(response);
          }
        }).bind(this)
      )
      .subscribe();
  }

  async buildLeaderboard(leaderboardData: Array<LeaderboardCard>): Promise<void> {
    if (this.organizations.length == 0) return;
    if (this.leaderboard.length == 0) return;
    this.top3Leaderboard = [];
    this.lower5Leaderboard = [];
    await Promise.all(leaderboardData.map((leaderCard, idx) => this.getUser(null, leaderCard)));
    leaderboardData.forEach((leaderCard, idx) => {
      //sorting out the top 3
      let rankDelta = ` ${leaderCard.rankDelta}`;
      let score: string = parseInt(leaderCard.score).toString();
      if (idx <= 2) {
        let self = "";
        if (this.userUUID == leaderCard.userUUID) self = " self";
        // create ADA friendly top results where the middle item is still read first
        // when using a screenreader
        if (idx == 0)
          return this.top3Leaderboard.push({
            ...leaderCard,
            class: `middle-user${self} ${rankDelta}`,
            score: score,
            size: 80
          });
        if (idx == 1)
          return this.top3Leaderboard.push({
            ...leaderCard,
            class: `left-user${self} ${rankDelta}`,
            score: score
          });
        return this.top3Leaderboard.push({
          ...leaderCard,
          class: rankDelta,
          score: score
        });
      } else {
        // check for uuid to match current user
        if (this.userUUID == leaderCard.userUUID) {
          if (idx == 7)
            return this.lower5Leaderboard.push({
              ...leaderCard,
              class: `self${rankDelta}`,
              idx: idx,
              score: score
            });
          return this.lower5Leaderboard.push({
            ...leaderCard,
            class: `self${rankDelta}`,
            score: score
          });
        } else {
          return this.lower5Leaderboard.push({
            ...leaderCard,
            class: rankDelta,
            score: score
          });
        }
      }
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
}
